<template>
	<van-space direction="vertical" :align="center" fill>

		<div v-if="!this.isEmpty(this.messages)" class="chat scroll-0">
			<div v-for="(message, index) of this.messages"
				 :key="index"
				 class="message"
				 :refs="'message_' + message.id"
				 :data-message-id="message.id"
				 :class="{
					 'incoming': message.is_incoming,
					 'outgoing': message.is_outgoing,
					 'outgoing-first': (!message.is_incoming && (!this.isEmpty(messages[index-1])) ? !(messages[index-1].is_outgoing) : true),
					 'outgoing-last': (!message.is_incoming && (!this.isEmpty(messages[index+1])) ? (messages[index+1].is_incoming) : false),
					 'small-message': message.message.length < 40,
					 'medium-message': message.message.length < 70 && message.message.length >= 40,
					 'large-message': message.message.length >= 70,
				 }">
				<div>
					<p>{{ message.message }}</p>
				</div>
				<div style="display: flex; flex-direction: row; margin-left: auto; margin-top: 5px; margin-bottom: 0px; margin-right: 0px;">
					<div style="display: inline-flex; color: #a7a7a7; font-size: 0.5rem; margin-left: auto; margin-right: 0px;">
						<span v-if="message.is_edited" style="padding-right: 6px;">edited</span>
						{{ message.datetime }}
					</div>
					<div v-if="message.is_outgoing" style="display: inline-flex; margin-left: 0px; margin-right: 0px;padding-left: 6px;">
						<img v-if="message.is_viewed && !message.is_delivered" :src="require('@/assets/icons/chat-viewed.svg')" style="opacity: 0.5;" height="9" />
						<img v-else-if="message.is_delivered && !message.is_viewed" :src="require('@/assets/icons/chat-delivered.svg')" style="opacity: 0.5;" height="9" />
						<img v-else :src="require('@/assets/icons/chat-awaiting-white.svg')" style="opacity: 0.5;" height="11" />
					</div>
				</div>
			</div>
		</div>
		<van-empty v-else
				   :image="require('@/assets/images/messages.svg')"
				   image-size="40%"
				   align="center"
				   description="Messages not found" />

		<van-form @submit="this.sendMessage()" class="message-form">
			<van-row align="center">
				<van-col v-if="this.form._mode == 'text'" span="2">
					<van-button size="small"
								type="default"
								plain
								round
								:icon="require('@/assets/icons/attachment-white.svg')" />
				</van-col>
				<van-col v-if="this.form._mode == 'voice'" span="22">
					fghfghfgh
				</van-col>
				<van-col v-else-if="this.form._mode == 'text'" span="20">
					<van-cell-group inset>
						<van-field v-model="this.form.message"
								   rows="1"
								   type="textarea"
								   placeholder="Message..."
								   maxlength="255"
								   :error-message="(!this.isEmpty(this.form._errors['message'])) ? this.form._errors['message'] : null"
								   show-word-limit
								   clearable
								   :autosize="{ maxHeight: 60, minHeight: 24 }">
						</van-field>
					</van-cell-group>
				</van-col>
				<van-col span="2">

					<van-button v-if="this.form._mode == 'voice' && this.isEmpty(this.form.audio)" size="small"
								type="primary"
								round
								:icon="require('@/assets/icons/send-white.svg')"
								@click="this.changeMode()" />
					<van-button v-else-if="this.form._mode == 'voice' && !this.isEmpty(this.form.audio)" size="small"
								type="danger"
								round
								:icon="require('@/assets/icons/record-white.svg')"
								@click="this.changeMode()" />

					<van-button v-if="this.form._mode == 'text' && this.isEmpty(this.form.message)" size="small"
								type="primary"
								round
								:icon="require('@/assets/icons/voice-white.svg')"
								@click="this.changeMode()" />
					<van-button v-else-if="this.form._mode == 'text' && !this.isEmpty(this.form.message)" size="small"
								type="primary"
								native-type="submit"
								round
								:icon="require('@/assets/icons/up-white.svg')" />
				</van-col>
			</van-row>
		</van-form>


	</van-space>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useCurrentLang } from 'vant';
import CommonService from '@/services/CommonService.js';

export default defineComponent({
	name: "messages",
	data() {
		return {
			title: "Messages",
			description: "Personal Messages",
			lang: 'en-US',
			translations: this.$root.intl.translations,
			messages: [
				{
					id: 135468,
					type: 'text',
					datetime: '2023-03-25 17:15:12',
					is_delivered: true,
					is_viewed: true,
					is_edited: false,
					is_incoming: false,
					is_outgoing: true,
					message: "Hey!",
				}, {
					id: 135469,
					type: 'text',
					datetime: '2023-03-25 17:15:32',
					is_delivered: true,
					is_viewed: true,
					is_edited: false,
					is_incoming: false,
					is_outgoing: true,
					message: "How are you?",
				}, {
					id: 135470,
					type: 'text',
					datetime: '2023-03-25 17:16:32',
					is_delivered: true,
					is_viewed: true,
					is_edited: false,
					is_incoming: false,
					is_outgoing: true,
					message: "This is a demo of Instagram chat theme where background of messages updates based on their position. Try writing few long messages and then scroll to see the effect.",
				}, {
					id: 135480,
					type: 'text',
					datetime: '2023-03-25 17:17:52',
					is_delivered: true,
					is_viewed: true,
					is_edited: false,
					is_incoming: false,
					is_outgoing: true,
					message: "Type your own messages or hit send without text to send random facts.",
				}, {
					id: 135481,
					type: 'text',
					datetime: '2023-03-25 17:18:43',
					is_delivered: true,
					is_viewed: true,
					is_edited: true,
					is_incoming: false,
					is_outgoing: true,
					message: "A cockroach can live several weeks with its head cut off. The common house fly cannot survive in Alaska. It is too cold. Those that do appear there are brought in by boat or plane and perish without reproducing. Mosquitoes, on the other hand, love cold weather. Specimens have been found near the North Pole.",
				}, {
					id: 135482,
					type: 'text',
					datetime: '2023-03-25 19:40:25',
					is_delivered: true,
					is_viewed: false,
					is_edited: false,
					is_incoming: false,
					is_outgoing: true,
					message: "A cockroach will live nine days without it's head, before it starves to death.",
				}, {
					id: 135486,
					type: 'text',
					datetime: '2023-03-25 19:41:12',
					is_delivered: true,
					is_viewed: false,
					is_edited: false,
					is_incoming: true,
					is_outgoing: false,
					message: "A Crocodile's tongue is attached to the roof of its mouth.",
				}, {
					id: 135487,
					type: 'text',
					datetime: '2023-03-25 19:41:56',
					is_delivered: false,
					is_viewed: false,
					is_edited: false,
					is_incoming: true,
					is_outgoing: false,
					message: "90 percent of all species that have become extinct have been birds.",
				}, {
					id: 135488,
					type: 'text',
					datetime: '2023-03-25 19:59:32',
					is_delivered: true,
					is_viewed: false,
					is_edited: false,
					is_incoming: true,
					is_outgoing: false,
					message: "A 10 foot tall emu was spotted walking the streets of New York in 1973, it had accidently escaped from a circus that specialized in large exotic birds. When police questioned the circus owners they responded saying \"George was constipated, so we thought a run around the grounds may help him feel better\" Police fined the circus 25 dollars, and 5 months later a bi-law was passed stating that all emus within New York City must be on a leash.",
				}
			],
			form: {
				audio: null,
				message: null,
				_mode: 'text',
				_errors: [],
			},
		};
	},
	components: {

	},
	mounted() {

		let _this = this;
		this.lang = useCurrentLang();
		this.setPageTitle(this.translate(this.title, this.lang));

		// Fallback linear gradient for mobile Safari
		if (CommonService.isMobile() && CommonService.isSafari()) {
			let chat = document.querySelector('.chat');
			if (chat) {
				chat.addEventListener("scroll", function () {
					CommonService.throttle(() => {
						_this.messageBgFallback();
					}, 60);
				});
			}
			this.messageBgFallback();
		}
	},
	methods: {
		setPageTitle(title) {
			CommonService.setPageTitle(title);
			this.emitter.emit("setPageTitle", title);
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		toRoute(path, refresh) {

			if (refresh)
				window.location.href = path
			else
				this.$router.push(path);

		},
		translate(string, lang) {

			if (typeof lang !== "undefined") {
				if (!this.isEmpty(this.translations[lang])) {
					if (!this.isEmpty(this.translations[lang][string])) {
						return this.translations[lang][string];
					}
				}
			}

			return string;
		},
		messageBgFallback() {
			document.querySelectorAll('.message').forEach((elmn) => elmn.style.backgroundSize = '99.9%');
			setTimeout(() => document.querySelectorAll('.message').forEach((elmn) => elmn.style.backgroundSize = '100%'));
		},
		/*setItemStyle(message) {
			let item = this.$refs['message_' + message.id];
			console.info(this.$refs);
			console.info(item);
			if (!this.isEmpty(item)) {
				let chat = getComputedStyle(document.body);
				let chat_width = Number(chat.getPropertyValue("--chatBoxWidth"));
				let border_width = chat_width - (item[0].getBoundingClientRect().width - (chat_width + 10));

				if (message.is_incoming) {
					item[0].setStyle({
						"borderRightWidth": Math.ceil(border_width) + 'px'
					});
				} else {
					item[0].setStyle({
						"borderLeftWidth": Math.ceil(border_width) + 'px'
					});
				}

				return item[0].style;
			}

			return {};
		},*/
		changeMode() {
			if (this.form._mode == 'text') {
				this.form._mode = 'voice';
				CommonService.debounce(() => {
					this.form.audio = 1;
				}, 1000);
			} else if (this.form._mode == 'voice') {
				this.form._mode = 'text';
				this.form.audio = null;
			}
		},
		sendMessage() {
			this.form._mode = 'text';
			this.form.audio = null;
			this.form.message = null;

			this.form._errors.push({
				'message': "Some error!"
			});
		},
	}
});
</script>

<style lang="scss">
* {
	box-sizing: border-box;
}

body {
	--bgColor: white;
	--borderRadius: 15px;
	--fontSize: calc(1rem * 0.9);
	--t1c3: #8929b7;
	--t1c2: #5749ca;
	--t1c1: #3a88e8;
	--t2c3: #8929b7;
	--t2c2: #e48063;
	--t2c1: #eaac70;
	--bgInput: rgba(0, 0, 0, 0.02);
	--bgInputActive: rgba(0, 0, 0, 0.05);
	--boxShadow: 0px 10px 30px 10px #0000001c;
	--colorIncoming: black;
	--bgColorIncoming: #efefef;
	--bgImg: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTYwLDAgaDQwIHY0MCBRMTAwLDAgNjAsMCIvPjwvc3ZnPg==');
	--bgImgSize: 40px;
	--chatBoxWidth: 300;
	--chatBoxWidthUnit: calc(var(--chatBoxWidth) * 1px);
	--edgeBorderWidth: 10px;
	transition: all 500ms ease;
}

.van-theme-dark {
	body {
		--bgColor: black;
		--bgInput: rgba(255, 255, 255, 0.05);
		--bgInputActive: rgba(255, 255, 255, 0.07);
		--boxShadow: 0px 10px 30px 10px #efefef1c;
		--colorIncoming: white;
		--bgColorIncoming: #222;
		--bgImg: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSJibGFjayIgZD0iTTYwLDAgaDQwIHY0MCBRMTAwLDAgNjAsMCIvPjwvc3ZnPg==');
	}
}

.chat {
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-top: 80vh;
	max-height: calc(100% - 122px);
	background: url('~@/assets/images/chat-pattern.svg'), linear-gradient(45deg, #f64f59 0%, #c471ed 50%, #12c2e9 100%);
	background-repeat: repeat;
	background-attachment: fixed;
	background-blend-mode: normal;
	right: 0;
	bottom: 74px;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 0;
	&::before {
		position: fixed;
		content: "";
		background: rgba(0, 0, 0, 0.42);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.message {
		position: relative;
		display: block;
		box-sizing: content-box;
		width: max-content;
		padding: 7px 10px;
		border: 1px solid transparent;
		max-width: 70%;
		font-weight: 300;
		color: white;
		margin: 0;
		margin-bottom: 0.5rem;
		background: #1c1c1c;
		z-index: 1;
		p {
			margin: 0;
		}
		&.incoming {
			margin-left: 0.5em;
			border-radius: 1em 1em 1em 1em;
			align-self: flex-start;
			background-color: var(--bgColorIncoming);
			color: var(--colorIncoming);
			&.small-message {
				border-radius: 1em 1em 1em 1em;
			}
			&.medium-message {
				border-radius: 0.8em 0.8em 0.8em 0.8em;
			}
			&.large-message {
				border-radius: 0.5em 0.5em 0.5em 0.5em;
			}
		}
		&.outgoing {
			margin-right: 0.5em;
			border-radius: 1em 1em 1em 1em;
			background-image: linear-gradient(45deg, #f64f59 0%, #c471ed 50%, #12c2e9 100%);
			background-origin: content-box;
			background-attachment: fixed;
			mask-composite: add;
			&.outgoing-first,
			&:first-child {
				border-top-left-radius: 1.5em;
			}

			&.small-message {
				border-radius: 1em 1em 1em 1em;
			}
			&.medium-message {
				border-radius: 0.8em 0.8em 0.8em 0.8em;
			}
			&.large-message {
				border-radius: 0.5em 0.5em 0.5em 0.5em;
			}

			& + .outgoing {
				border-top-right-radius: 0.4em;
				border-bottom-right-radius: 0.4em;
			}

			&.outgoing-last,
			&:last-child {
				border-bottom-left-radius: 1.5em;
			}
		}
	}
	.outgoing + .incoming,
	.incoming + .outgoing {
		border-radius: 0.1em 0.1em 0.1em 0.1em;
	}
}

.message-form {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 5px;
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	background: var(--bgColor);
	border-top: 1px solid var(--bgColorIncoming);
	z-index: 1;
}

@media (prefers-color-scheme: dark) {
	body {

	}
}

.chat {
	--chatBoxWidthUnit: 375px;
	.message {
		position: relative;
		&.small-message {
			max-width: 22ch;
			--bgImgSize: 40px;
		}
		&.medium-message {
			max-width: 28ch;
			--bgImgSize: 30px;
		}
		&.large-message {
			max-width: 34ch;
			--bgImgSize: 24px;
		}
	}
}
</style>